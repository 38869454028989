import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.adjustHeight();
  }

  adjustHeight() {
    const maxHeight = 200;
    this.element.style.height = "0";
    const scrollHeight = Math.min(this.element.scrollHeight, maxHeight);
    this.element.style.height = `${scrollHeight}px`;
  }
}
